.zoomBackdropBackground {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 120%;
    background-position: center;
    transition: opacity 0.5s ease-in-out;
    position: fixed;
    top:0;
    left: 0;
    z-index: 1;

    &.-leftToRight {
        animation: moveLeftToRight 6s linear;
    }
    &.-rightToLeft {
        animation: moveRightToLeft 6s linear;
    }
}

@media screen and (max-width: 1099px) {

    .zoomBackdropBackground {
        background-size: 130%;

        &.-leftToRight {
            animation: tabletMoveLeftToRight 6s linear;
        }
        &.-rightToLeft {
            animation: tabletMoveRightToLeft 6s linear;
        }
    }
}

@keyframes moveLeftToRight {

    0% {
        background-size: 100%;
        background-position: left center;
    }
    25% {
        background-size: 110%;
        background-position: left center;
    }
    50% {
        background-size: 120%;
        background-position: left center;
    }
    75% {
        background-size: 130%;
        background-position: left center;
    }
    100% {
        background-size: 140%;
        background-position: left center;
    }
}

@keyframes tabletMoveLeftToRight {

    0% {
        background-size: 140%;
        background-position: left center;
    }
    25% {
        background-size: 150%;
        background-position: left center;
    }
    50% {
        background-size: 160%;
        background-position: left center;
    }
    75% {
        background-size: 170%;
        background-position: left center;
    }
    100% {
        background-size: 180%;
        background-position: left center;
    }
}

@keyframes moveRightToLeft {

    0% {
      background-size: 100%;
      background-position: right center;
    }
    25% {
        background-size: 110%;
        background-position: right center;
    }
    50% {
      background-size: 120%;
      background-position: right center;
    }
    75% {
        background-size: 130%;
        background-position: right center;
    }
    100% {
      background-size: 140%;
      background-position: right center;
    }
}

@keyframes tabletMoveRightToLeft {

    0% {
      background-size: 140%;
      background-position: right center;
    }
    25% {
        background-size: 150%;
        background-position: right center;
    }
    50% {
      background-size: 160%;
      background-position: right center;
    }
    75% {
        background-size: 170%;
        background-position: right center;
    }
    100% {
      background-size: 180%;
      background-position: right center;
    }
}

@media screen and (max-width: 720px) {

    .zoomBackdropBackground {
        width: 0;
        height: 0;
        opacity: 0 !important;
    }
}