@import '../../resources/styles/colors.scss';

$border-color_1: $white transparent transparent transparent;

@keyframes lds-ring {

    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.lds-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: relative;

    div {
        display: block;
        width: 64px;
        height: 64px;
        border: 8px solid $white;
        border-radius: 50%;
        border-color: $border-color_1;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        margin: 8px;
        box-sizing: border-box;
        position: absolute;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}
.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
        font-size: 24px;
        color: $white;
        text-align: center;
    }
}

@media screen and (max-width: 720px) {

    .loaderContainer {
    
        &-title {
            font-size: 16px;
        }
    }
}
