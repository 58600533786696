@import '../../../resources/styles/colors.scss';

@media screen and (max-width: 720px) {

    .homeContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100vw;
        height: 100vh;
        background-color: $black;
        overflow-x: hidden;

        & .header {
            position: relative;
        }
        &-suggestPhrase {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 6%;
            font-size: 6.2vw;
            font-weight: bold;
            color: $white;
            border-radius: 5px;
            padding: 10px 20px;
            margin: 0;
            position: relative;
            top: auto;
            left: auto;
        }
        &-selectionType {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding: 15px 20px;
            z-index: 2;
        }
        &-titleButtonSection {
            display: flex;
            flex-direction: column;
            padding: 15px 20px;
            gap: 8px;
            z-index: 2;
        }
        & h3 {
            line-height: auto;
            font-size: 5.2vw;
            color: $white;
            text-align: center;
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
}
