@import '../../resources/styles/colors.scss';

.modalBox {
  min-width: 30vw;
  max-height: 95vh;
  border: 0;
  border-radius: 10px;
  background: $modal-overlay;
  box-shadow: 0 3px 6px rgba($black, 0.3);
  transform: translate(-50%, -50%);
  padding: 20px;
  overflow-y: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 120;

  &:focus-visible {
    outline: none !important;
  }
  &-title {
    font-size: 20px;
    font-weight: 600;
    color: $white;
    padding-bottom: 15px;
    margin: 0;
  }
  &-subtitle {
    font-size: 16px;
    color: $white;
    opacity: 0.8;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
  }
  &-buttonsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    margin-top: 20px;
    gap: 10px;

    &_cancel {
      color: rgba($white, 0.8);
      cursor: pointer;
    }
    &_confirm {
      font-weight: bold;
      color: $movie;
      margin-left: 30px;
      cursor: pointer;
    }
  }
  &-containerList {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 50vh;
    padding: 0 3px;
    overflow-x: hidden;
    overflow-y: scroll;

    &Grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;

      &Content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 275px;
        border-radius: 10px;
        background-color: $black;
        cursor: pointer;
        position: relative;
        flex: 0 0 197px;
  
        &Title {
          color: $white;
          position: absolute;
          z-index: 1;
        }
        &Cover {
          max-width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
          position: relative;
          z-index: 2
        }
        &Selected {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border: 1px solid rgba($white, 0.4);
          border-radius: 10px;
          background-color: rgba($black, 0.6);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
  
          & i {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background-color: rgba($white, 0.4);
          }
        }
      }
    }
  }
  &.-social {

    & .modalBox-subtitle {

      & .button {
        display: inline-block;

        & p {
          font-size: 16px;
        }
      }
    }
  }
  &.-suggestions {
    max-height: 80vh;
  }
}
.selectFeelingsModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  max-height: 80vh;

  &-feelingsGrid {
    display: grid;
    max-height: 100%;
    margin: 15px;
    overflow-y: scroll;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}

@media screen and (max-width: 720px) {

  .modalBox {
    width: 80vw;

    &-title {
      font-size: 20px;
      text-align: center;
    }
    &-buttonsRow {
      flex-direction: column-reverse;
      margin-top: 0
    }
    &-typeRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
    }
    &-containerList {

      &Grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &.-social {

      & .modalBox-buttonsRow {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
  }
}