@import '../../../resources/styles/colors.scss';

@media screen and (max-width: 720px) {

    .accountMobileContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100vw;
        min-height: 100vh;
        background-color: $black;
        position: relative;
        gap: 8px;

        & .header {
            position: relative;
            z-index: 2;
        }
        &-content {
            display: flex;
            flex-direction: column;
            width: calc(100vw - 40px);
            padding: 15px 20px;
            z-index: 2;

            &_title {
                width: 100%;
                font-size: 32px;
                font-weight: bold;
                color: $white;
                text-align: center;
                margin: 10px 0;
                z-index: 2;
            }
            &_subtitle {
                font-size: 24px;
                font-weight: normal;
                color: $white;
                text-align: left;
                margin: 5px 0;
                z-index: 2;
            }
            &_minorTitle {
                font-size: 20px;
                font-weight: normal;
                color: $white;
                text-align: left;
                margin: 0;
            }
            &_mainRow {
                margin-top: 20px;
            }
            &_actionRow {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                margin-bottom: 15px;
            }
            &_carousel {
                display: flex;
                flex-direction: row;
                width:100vw;
                white-space: nowrap;
                margin-right: -20px;
                margin-left: -20px;
                overflow-x: scroll;
                gap: 10px;

                &::-webkit-scrollbar {
                    height: 0;
                    padding: 0;
                }
                &__noElements {
                    font-size: 14px;
                    color: rgba($white,0.6);
                    margin: 0px 20px;
                }
                & div {

                    &.greyButton {
                        width: 14vw;
                        flex: 0 0 14vw;
                        height: 14vw;
                        background-color: rgba($grey,0.6);
                    }
                    &:nth-child(1) {
                        margin-left: 20px;
                    }
                    &:last-child {
                        margin-right: 20px;
                    }
                }
                &.-contents {
                    gap: 8px;

                    & div {
                        flex: 0 0 32vw;

                        & img {
                            max-width: 100%;
                            height: auto;
                            border-radius: 6px;
                            object-fit: cover;
                        }
                    }
                    
                }
            }
            &_list {
                height: calc(60vh - 40px);
            }
            &.-platform,
            &.-contents,
            &.-settings {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
            &.-settings {
                gap: 40px;
            }
            & .changeType {
                margin-top: 0px;
                margin-bottom: 0px;
                z-index: 2;
            }
            & .button {
                z-index: 2;
            }
        }
    }
}