@import '../../../resources/styles/colors.scss';

.filterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 16vw);
    height: 100vh;
    background-color: $black;
    padding: 0 8vw;
    position: relative;
    gap: 40px;

    &-filterSelection {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        gap: 25px;
        flex: 1;
        z-index: 2;
    }
    &-buttonRow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 20px;
        flex: 1;
        z-index: 2;

        &__popover {
            display: flex;
            align-items: center;
            background: rgba($white, 0.15);
            padding: 10px 20px;

            & p {
                color: $white;
                cursor: pointer;
            }
        }
    }
}
