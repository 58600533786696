@import '../../../resources/styles/colors.scss';

@media screen and (max-width: 720px) {

    .resultContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100vw;
        height: 100%;
        min-height: 100vh;
        background-color: $black;
        position: relative;

        & .header {
            position: relative;
            z-index: 2;
        }
        & h3 {
            font-size: 22px;
            font-weight: bold;
            color: $white;
            margin: 15px 0 0;
        }
        & p {
            font-size: 16px;
            color: $white;
            text-align: center;
        }
        &-loading,
        &-notFound {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
        }
        &-contentMobile {
            z-index: 2;

            &_coverContainer {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 55vh;

                &__meta {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    white-space: nowrap;
                    flex: 1;
                    padding: 0 15px 0 5px;

                    &--duration {
                        display: flex;
                        align-items: center;   
                        color: $white;
                        margin-bottom: 10px;
                        gap: 5px;                 
                    }
                    &--stars {
                        display: flex;
                        flex-direction: column-reverse;
                        gap: 5px;
                    }
                    & .-rotate {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        transform: rotate(270deg);
                        flex: 1;
                    }
                }
                &__cover {
                    flex: 3;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-end;
                    position: relative;

                    & img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                    &--buttons {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        width: calc(100% - 30px);
                        background: $black;
                        background: linear-gradient(180deg, rgba($black, 0.5) 20%, rgba($black, 0) 100%);
                        padding: 15px;
                        position: absolute;
                    }
                    &--recommended {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: center;
                        width: calc(100% - 20px);
                        background: $black;
                        background: linear-gradient(180deg, rgba($black,0) 0%, rgba($black,0.5) 30%, rgba($black,0.8) 60%) !important;
                        padding: 5px 10px;
                        position: absolute;
                        bottom: 0;
                        gap: 8px;
        
                        &Icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 40px;
        
                            &.-movie {
                                background-color: $movie;
                            }
                            &.-tvShow {
                                background-color: $tv-show;
                            }
                        }
                        &Text {
                            color: $white !important;
                            flex: 1;
                            text-align: left !important;

                            &Title {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            &_data {
                display: flex;
                flex-direction: column;
                max-width: calc(100vw - 40px);
                padding: 15px 20px;
                gap: 8px;

                &__title {
                    line-height: auto;
                    font-size: 24px;
                    color: $white;
                    margin: 15px 0 8px;
                }
                &__director {
                    font-size: 16px;
                    color: $white;
                    text-align: left !important;
                }
                &__genresCarousel {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    flex-wrap: wrap;

                    &--genre {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        color: rgba($white,0.6);
                        border: 1px solid rgba($white,0.15);
                        border-radius: 20px;
                        border-color: transparent;
                        background-color: rgba($white,0.15);
                        padding: 4px 8px;

                        &.-selected {

                            &.-movie {
                                color: $movie;
                                border-color: $movie;
                            }
                            &.-show {
                                color: $tv-show;
                                border-color: $tv-show;
                            }
                        }
                    }
                }
                &__plot {
                    display: flex;
                    gap: 8px;
                    flex-direction: column;

                    & p {
                        font-size: 14px;
                        color: rgba($white,0.8);
                        text-align: left;
                        margin: 0;
                    }
                }
                &__cast {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    width: 100vw;
                    white-space: nowrap;
                    margin-right: -20px;
                    margin-left: -20px;
                    overflow-x: scroll;
                    gap: 10px;

                    &::-webkit-scrollbar {
                        height: 0;
                        padding: 0;
                    }
                    &--actor {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        max-width: 18vw;

                        &:nth-child(1) {
                            margin-left: 20px;
                        }
                        &:last-child {
                            margin-right: 20px;
                        }
                        & img {
                            width: 18vw;
                            height: 18vw;
                            border-radius: 1.2vw;
                            object-fit: cover;
                        }
                        & span {
                            font-size: 10px;
                            color: $white;
                            text-align: center;
                            white-space: normal;
                        }
                    }
                }
                &__feelings {
                    display: flex;
                    flex-direction: row;
                    width:100vw;
                    white-space: nowrap;
                    margin-right: -20px;
                    margin-bottom: 20px;
                    margin-left: -20px;
                    overflow-x: scroll;
                    gap: 10px;

                    &::-webkit-scrollbar {
                        height: 0;
                        padding: 0;
                    }
                    & .feelingItem {
                        width: calc(70px - 20px);
                        height: calc(70px - 20px);
                        border-radius: 1.2vw;
                        flex: 0 0 calc(70px - 20px);

                        &:nth-child(1) {
                            margin-left: 20px;
                        }
                        &:last-child {
                            margin-right: 20px;
                        }
                        & img {
                            width: 8vw;
                            height: auto;
                        }
                        & p {
                            font-size: 10px;
                        }
                    }
                }
                &__providers {
                    display: flex;
                    flex-direction: row;
                    width: 100vw;
                    white-space: nowrap;
                    margin-right: -20px;
                    margin-left: -20px;
                    overflow-x: scroll;
                    gap: 10px;

                    &::-webkit-scrollbar {
                        height: 0;
                        padding: 0;
                    }
                    & .greyButton {
                        width: calc(70px - 10px);
                        height: calc(70px - 10px);
                        flex: 0 0 calc(70px - 10px);
                        background-color: rgba($grey,0.6);

                        &:nth-child(1) {
                            margin-left: 20px;
                        }
                        &:last-child {
                            margin-right: 20px;
                        }
                    }
            }
        }
    }
}}