@import '../../../resources/styles/colors.scss';

@media screen and (max-width: 720px) {

    .filterContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100vw;
        min-height: 100vh;
        background-color: $black;
        padding: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        position: relative;
        gap: 8px;

        & .header {
            position: relative;
            z-index: 2;
        }
        &-content {
            display: flex;
            flex-direction: column;
            width: calc(100vw - 40px);
            height: 100%;
            padding: 15px 20px;
            z-index: 2;

            &_step {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 20px;
                width: 100%;
                height: 100%;

                &__row {
                    display: flex;
                    flex-direction: row;
                    gap: 40px;
                    justify-content: center;
                    width: 100%;
                }
                &__range {
                    width: 100%;
                    font-size: 20px;
                    font-weight: bold;
                    color: $white;
                    text-align: center;
                    margin: 0;
                }
                &__flex1 {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    flex: 1;
                }
                & .yearSelectorContainer-sliderYear {
                    height: auto;
                    padding: 0 10px 0 15px;
                }
            }
        }
    }
}