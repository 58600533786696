.footerSettings {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 40px);
    padding: 20px;
    position: fixed;
    bottom: 2vh;
    left: 0;
    z-index: 2;
}
