@import '../../resources/styles/colors.scss';

button {
    width: auto;
    border: none;
    background: none;
    box-shadow: none;
    outline: none;

    &.button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 6px;
        opacity: 1;
        padding: 15px 20px;
        cursor: pointer;
        gap: 6px;
    
        & span {
            line-height: 1;
            font-size: 16px;
            font-weight: 600;
            color: $white;
            padding: 0;
            margin: 0;
    
            &.-uppercase {
                text-transform: uppercase;
            }
        }
        &.-disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
        &.-gradient {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 3px solid #0000;
            background: $tv-show;
            background: linear-gradient($black, $black) padding-box, linear-gradient(
                var(--angle),
                $movie,
                $tv-show
              ) border-box;
            animation: 6s rotate linear infinite;
            padding: 12px;
        }
        &.-revertGradient {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: 3px solid #0000;
            background: $tv-show;
            background: linear-gradient($black, $black) padding-box, linear-gradient(
                var(--angle),
                $tv-show,
                $movie
              ) border-box;
            animation: 6s rotate linear infinite;
            padding: 12px;
        }
        &.-solidYellow {
            background-color: $tv-show;
    
            & span {
                color: $black;
            }
        }
        &.-solidPurple {
            background-color: $movie;
        }
        &.-whiteTransparent {
            border: 1px solid $white;
            background-color: rgba($white,0.4);
    
            &.-disabled {
                opacity: 0.3;
            }
        }
        &.-link {
            width: auto;
            background-color: transparent;
            padding: 0;
    
            &Yellow span {
               color: $tv-show;
            }
            &Purple span {
                color: $movie;
            }
            &Delete span {
                color: $delete;
            }
            &Opacity span {
                color: $white;
                opacity: 0.5;
            }
            &White span {
                line-height: 12px;
                color: $white;
                padding: 10px;
                position: relative;
    
                &::after {
                    content: '';
                    display: inline-block;
                    width: 100%;
                    height: 1px;
                    background: $white;
                    background: linear-gradient(90deg, rgba($white,0) 0%, rgba($white,0.4) 50%, rgba($white,0) 100%);
                    margin: auto;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}

@keyframes rotate {

    to {
      --angle: 360deg;
    }
  }
  
  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }