@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body {
    font-family: 'Lato', sans-serif;
    background-color: #000;
    margin: 0;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.gradientBG {
    background: rgb(247, 214, 50);
    background: linear-gradient(150deg, rgba(247, 214, 50, 1) 5%, rgba(243, 22, 180, 1) 100%);
}
.revertGradientBG {
    background: rgb(243, 22, 180);
    background: linear-gradient(150deg, rgba(243, 22, 180, 1) 0%, rgba(247, 214, 50, 1) 95%);
}
.movieBG {
    background-image: url('./resources/images/BG_MOVIE.png');
    background-repeat: repeat;
}
.tvBG {
    background-image: url('./resources/images/BG_TVSHOW.png');
    background-repeat: repeat;
}

/* width */

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.4);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.8);
}
.tooltip {
    position: relative;
}
.tooltip .tooltipText {
    width: 10vw;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    border-radius: 6px;
    background-color: #111;
    visibility: hidden;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    z-index: 1;
}
.tooltip:hover .tooltipText {
    visibility: visible;
}
.tooltipText:before {
    content: '';
    display: block;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #111;
    border-left: 10px solid transparent;
    transform: translate(-50%, 0);
    position: absolute;
    top: -10px;
    left: 50%;
}
p {
    margin: 0;
}
input {
    font-size: 16px;
    color: #fff;
    border: 0;
    border-radius: 5px;
    background-color: #515151;
    box-shadow: none;
    padding: 12px;
}
input:focus {
    outline: none;
}
.box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    padding: 20px;
}
.box.success {
    color: rgb(243, 22, 180);
    border-color: rgb(243, 22, 180);
    background-color: rgba(243, 22, 180, 0.4);
}
.box.error {
    color: rgb(252, 35, 35);
    border-color: rgb(252, 35, 35);
    background-color: rgba(252, 35, 35, 0.4);
}
.cookieButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #252525;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 4px;
    position: fixed;
    bottom: 40px;
    right: 28px;
    z-index: 9999999;
}
.cookieButton svg {
    width: 24px;
    height: 24px;
}
.termsfeed-com---palette-dark .cc-nb-okagree,
.termsfeed-com---palette-dark .cc-nb-reject,
.termsfeed-com---palette-dark .cc-cp-foot-save {
    background-color: #f7d632;
}
.termsfeed-com---pc-dialog input[type='checkbox'].cc-custom-checkbox:checked + label:before {
    background-color: #f316b4;
}
.termsfeed-com---palette-dark .cc-pc-head-close:active,
.termsfeed-com---palette-dark .cc-pc-head-close:focus {
    border-color: #f7d632;
}

@media screen and (max-width: 600px) {
    .termsfeed-com---nb-simple {
        max-width: 100% !important;
    }
}

.grecaptcha-badge {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
}
