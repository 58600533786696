@import '../../../resources/styles/colors.scss';

.accountContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100vw;
    height: 100vh;
    background-color: $black;

    &-tabRendered {
        height: 100%;
        max-height: 72vh;
        margin-top: 150px;

        &_filterContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: calc(100vw - 16vw);
            height: 100%;
            background-color: $black;
            padding: 0 8vw;
            position: relative;
            gap: 40px;

            & .changeType {
                margin-top: 2vh;
            }
            &-filterSelection {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                max-width: 64vw;
                gap: 40px;
                flex: 1;
                z-index: 2;
            }
            &-buttonRow {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: 20px;
                flex: 1;
            }
        }
        &_watchListContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: calc(100vw - 10vw);
            height: 100%;
            background-color: $black;
            padding: 0 5vw;
            position: relative;
            gap: 40px;

            & .changeType {
                margin-top: 2vh;
            }
            &-listContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 60vh;
                border-radius: 20px;
                background: rgba($white, 0.15);
                padding: 20px;

                & .lds-ring div {
                    width: 32px;
                    height: 32px;
                    border-width: 4px;
                }
            }
        }
        &_settingsContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: calc(100vw - 16vw);
            height: 100%;
            background-color: $black;
            padding: 0 8vw;
            overflow-y: scroll;
            position: relative;
            gap: 40px;
        }
    }
}

@media screen and (max-width: 1699px) {

    .accountContainer {

        &-tabRendered {
            max-height: 64vh;
            margin-top: 50px;

            &_filterContainer {
                width: calc(100vw - 10vw);
                padding: 0 5vw;
                gap: 15px;

                &-filterSelection {
                    max-width: 100%;
                }
            }
            &_settingsContainer {
                width: calc(100vw - 10vw);
                padding: 0 5vw;
                gap: 40px;
            }
        }
    }
}

@media screen and (max-width: 1599px) {

    .accountContainer {

        &-tabRendered {
            margin-top: 120px;

            &_settingsContainer {
                gap: 40px;
            }
        }
    }
}
