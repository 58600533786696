@import '../../../resources/styles/colors.scss';

.formField {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    &_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 18px;
        border-radius: 8px;
        background-color: $grey;
        padding: 12px;

        & input {
            border-radius: 0;
            background-color: $grey;
            padding: 0;
            flex: 1;

            &::placeholder {
                color: rgba($white, 0.5)
            }
        }
        & i {
            cursor: pointer;
        }
    }
    &_checkboxRow {
        display: flex;
        flex-direction: row;
        gap: 8px;
        color: #fff;

        & a {
            color: #fff;
        }
    }
    &_error {
        font-size: 14px;
        color: $delete;
    }
}