@import '../../resources/styles/colors.scss';

.notFoundPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: $black;
    gap: 14px;

    & h1 {
        font-size: 200px;
        color: $movie;
        padding: 0;
        margin: 0;
    }
    & h2 {
        font-size: 24px;
        font-weight: normal;
        color: rgba($white,0.6);
        margin: 0;
    }
}

@media screen and (max-width: 720px) {

    .notFoundPage {

        & h1 {
            font-size: 120px;
        }
    }
}