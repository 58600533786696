@import '../../resources/styles/colors.scss';

.yearSelectorContainer {
  height: 100%;

  & h3 {
    font-size: 20px;
    font-weight: bold;
    color: $white;
  }
  &-sliderYear {
    height: 45vh;
  }
}