@import '../../../resources/styles/colors.scss';

.homeContainer {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
    background-color: $black;

    &-suggestPhrase {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: fixed;
        top: calc(20px + 10vw);
        left: 0;
        z-index: 2;

        & p {
            font-size: 1.2vw;
            font-weight: bold;
            color: $black;
            text-transform: uppercase;
            border-radius: 5px;
            background: $white;
            padding: 10px 20px;
        }
    }
    &-section {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex: 1;
        z-index: 2;
    }
}
