@import '../../resources/styles/colors.scss';

.changeType {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  color: $white;
  text-transform: uppercase;
  margin-top: 7vh;
  gap: 35px;
  z-index: 2;

  & span {
    cursor: pointer;
  }
}