@import '../../resources/styles/colors.scss';

.filterStepQuestion {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    color: $white;
    gap: 20px;

    &-number {
        font-size: 36px;
        font-weight: bold;
    }
    &-text {
        font-size: 24px;
        font-weight: normal;
        text-align: left;
        white-space: pre-line;
        margin: 0;
        flex: 1;
    }
}