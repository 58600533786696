@import '../../resources/styles/colors.scss';

.feelingItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 10px;
    background-color: $grey;
    padding: 10px;
    cursor: pointer;

    & img {
        width: 60px;
        height: 60px;
    }
    & p { 
        font-weight: normal;
        color: $white;
        margin: 0;
    }
}

@media screen and (max-width: 720px) {

    .feelingItem {
        width: 23vw;
        height: 23vw;
        background-color: rgba($grey,0.6);

        & img {
            width: 75px;
            height: 75px;
        }
    }
}