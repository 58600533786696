@import '../../resources/styles/colors.scss';

.loginContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    background-color: $black;
    position: relative;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        padding: 0;
        z-index: 2;
    }
    & .header {
        z-index: 2;
    }
    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100vw - 20vw);
        height: 65vh;
        margin-right: 10vw;
        margin-left: 10vw;
        gap: 20px;
        z-index: 2;

        &_formColumn {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
            border-right: 1px solid $grey-light;
            padding: 1vh 2vw;
            overflow-x: hidden;
            overflow-y: scroll;
            flex: 2;
            gap: 20px;

            &__columnContent {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 20px;

                & form {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 20px;
                }
                &-socialLoginContainer {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    gap: 15px;

                    & button {
                        flex: 1;
                    }
                }
                &--checkPassword {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: rgba($white,0.7);
                    gap: 6px;

                    & .description {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 14px;
                        gap: 4px;

                        & .button span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        &_descriptionColumn {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
            padding: 5vh 2vw;
            flex: 3;
            gap: 20px;
        }
        & h2 {
            margin: 5px 0;
        }
    }
}

@media screen and (max-width: 1599px) {

    .loginContainer {
        align-items: flex-end;
        width: 100%;
        overflow-y: hidden;

        &-content {
            height: 75vh;

            &_formColumn {

                &__columnContent {

                    &-socialLoginContainer {
                        justify-content: flex-start;
                        width: 100%;

                        & button {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {

    .loginContainer {
        flex-direction: column;
        overflow-y: scroll;

        & .header {
            position: relative;
        }
        &-content {
            flex-direction: column;
            width: calc(100% - 30px);
            height: auto;
            padding: 0 15px;
            margin: 0;
            
            &_formColumn {
                width: 100%;
                border-right: none;
                overflow: inherit;
            }
        }
    }
}