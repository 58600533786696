@import '../../../resources/styles/colors.scss';

.feelingsPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    background-color: $black;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    & .header {
        position: relative;
        z-index: 2;
    }
    &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        font-size: 7vw;
        font-weight: bold;
        color: $white;
        border-radius: 5px;
        padding: 10px 20px;
        margin: 0;
        position: relative;
        top: auto;
        left: auto;
        z-index: 2;
    }
    &-feelingsGrid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        padding: 15px 20px;
        z-index: 2;
        gap: 8px;
        flex: 1;
    }
    &-titleButtonSection {
        display: flex;
        flex-direction: column;
        padding: 15px 20px;
        z-index: 2;
        gap: 8px;
    }
    & .button {
        z-index: 2;
    }
    & h3 {
        line-height: auto;
        font-size: 6.5vw;
        color: $white;
        text-align: center;
        z-index: 2;
    }
}