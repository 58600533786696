@import '../../resources/styles/colors.scss';

.contentsGrid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    gap: 8px;
}
.noContents {
    font-size: 16px;
    color: rgba($white,0.6);
}

@media screen and (max-width: 720px) {

    .contentsGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-bottom: 40px;
    }
    
}