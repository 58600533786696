@charset "UTF-8";

@font-face {
  font-family: "whatwatch";
  src: url("../../../public/assets/fonts/whatwatch.eot");
  src: url("../../../public/assets/fonts/whatwatch.eot?#iefix") format("embedded-opentype"), url("../../../public/assets/fonts/whatwatch.woff") format("woff"), url("../../../public/assets/fonts/whatwatch.ttf") format("truetype"), url("../../../public/assets/fonts/whatwatch.svg#whatwatch") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
  line-height: 1;
  font-family: "whatwatch", fantasy !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: none !important;
  font-variant: normal !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="whatwatch-"]:before, [class*=" whatwatch-"]:before {
  line-height: 1;
  font-family: "whatwatch", fantasy !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-transform: none !important;
  font-variant: normal !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.whatwatch-accept:before {
  content: "\61";
}
.whatwatch-apple:before {
  content: "\62";
}
.whatwatch-chevron-down:before {
  content: "\64";
}
.whatwatch-chevron-up:before {
  content: "\65";
}
.whatwatch-close:before {
  content: "\66";
}
.whatwatch-dislike:before {
  content: "\67";
}
.whatwatch-duration:before {
  content: "\68";
}
.whatwatch-facebook:before {
  content: "\69";
}
.whatwatch-favorite:before {
  content: "\6a";
}
.whatwatch-google:before {
  content: "\6b";
}
.whatwatch-like:before {
  content: "\6c";
}
.whatwatch-remove:before {
  content: "\6d";
}
.whatwatch-settings:before {
  content: "\6e";
}
.whatwatch-share:before {
  content: "\6f";
}
.whatwatch-star-fill:before {
  content: "\70";
}
.whatwatch-star-void:before {
  content: "\71";
}
.whatwatch-trailer:before {
  content: "\72";
}
.whatwatch-user:before {
  content: "\73";
}
.whatwatch-watchlist:before {
  content: "\74";
}
.whatwatch-share-apple:before {
  content: "\75";
}
.whatwatch-calendar:before {
  content: "\63";
}
.whatwatch-bookmark:before {
  content: "\76";
}
.whatwatch-eye:before {
  content: "\77";
}
.whatwatch-eye-slash:before {
  content: "\78";
}
