@import '../../resources/styles/colors.scss';

.settingsItem {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  &_columnDescription {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & h2 {
      font-size: 24px;
      font-weight: bold;
      color: $white;
      padding: 0;
      margin: 0;
    }
    & p {
      font-size: 18px;
      color: rgba($white,0.75);
      padding: 0;
      margin: 0;
    }
  }
}