@import '../../../resources/styles/colors.scss';

.resultContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    background-color: $black;
    position: relative;

    &-loading,
    &-notFound {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 60vw;
        height: 65vh;
        margin-right: 10vw;
        margin-left: 10vw;
        gap: 20px;
        z-index: 2;

        &--indicators {

            &_up,
            &_down {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2vw;
                height: 2vw;
                border: 1px solid $white;
                border-radius: 0.5vw;
            }
        }
        &--cover {
            flex: 2;
            min-width: 350px;
            height: 100%;
            position: relative;

            &_imgContainer {
                width: 100%;
                height: 60vh;
                border-radius: 0.8vw;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;

                & img {
                    width: 100%;
                    height: 60vh;
                    border-radius: 0.8vw;
                    object-fit: cover;
                }
            }
            &_signalRow {
                width: 1px;
                height: 100vh;
                background: $white;
                position: fixed;
                top: 0;
                left: 15vw;
                z-index: 1;
            }
            &_reactions {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 20px);
                padding: 5px 10px;
                position: absolute;
                top: 0;
            }
            &_recommended {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                width: calc(100% - 20px);
                background: $black;
                background: linear-gradient(180deg, rgba($black,0) 0%, rgba($black,0.8) 70%);
                padding: 5px 10px;
                position: absolute;
                bottom: 0;
                gap: 8px;

                &Icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    background-color: $black;

                    &.-movie {
                        background-color: $movie;
                    }
                    &.-tvShow {
                        background-color: $tv-show;
                    }
                }
                &Text {
                    color: $white !important;
                    flex: 1;
                    text-align: left !important;

                    &Title {
                        font-weight: bold !important;
                    }
                }
            }
        }
        &--contentData {
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-top: 2vh;
            flex: 3.5;

            &_titleSection {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 15px;

                &__title {
                    font-size: 2.4vw;
                    font-weight: bold;
                    color: $white;
                    margin-bottom: 0;
                    flex: 1;
                }
            }
            &_fixedData {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 15px;

                &__genres {
                    font-size: 1.1vw;
                    color: $white;
                }
                &__stars {
                    display: flex;
                    justify-content: flex-end;
                    gap: 5px;
                }
            }
            &_parts {

                &__up,
                &__down {
                    display: flex;
                    flex-direction: column;
                    gap: 3vh;
                    margin-top: 36px;

                    &-trailer,
                    &-director,
                    &-cast,
                    &-plot {
                        display: flex;
                        font-size: 1.1vw;
                        color: $white;
                        gap: 15px;
                    }
                    & p {
                        margin: 0;
                    }
                    &-platforms {
                        display: flex;
                        flex-direction: column;
                        font-size: 1.1vw;
                        color: $white;
                        gap: 5px;

                        &--list {
                            display: flex;
                            justify-content: flex-start;
                            gap: 10px;

                            & img {
                                width: 3vw;
                                height: 3vw;
                                border-radius: 0.5vw;
                            }
                        }
                    }
                    &-lastData {
                        display: flex;
                        gap: 5vw;
                        justify-content: flex-start;

                        &--date,
                        &--duration {
                            display: flex;
                            font-size: 1.1vw;
                            color: $white;
                            gap: 15px;
                        }
                    }
                }
            }
        }
    }
}
