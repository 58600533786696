@import '../../resources/styles/colors.scss';

.justWatchReferenceContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 10px;
    gap: 8px;

    & span {
        font-size: 10px;
        color: rgba($white,0.6);
    }
    & img {
        width: auto;
        height: 12px;
        cursor: pointer;
    }
}

@media screen and (max-width: 720px) {

    .justWatchReferenceContainer {
        margin-top: 0;
    
        & span {
            font-size: 10px;
        }
        & img {
            height: 10px;
        }
    }
}