$movie: #F316B4;
$tv-show: #F7D632;
$white: #fff;
$black: #000;
$grey: #515151;
$grey-light: #707070;
$delete: #fc2323;
$modal-overlay: #212121;
$gradient: linear-gradient(150deg, rgba($movie, 1) 0%, rgba($tv-show, 1) 95%);
$gradient-revert: linear-gradient(150deg, rgba($tv-show, 1) 5%, rgba($movie, 1) 100%);
