@import '../../resources/styles/colors.scss';

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 275px;
    border-radius: 10px;
    background-color: $black;
    cursor: pointer;
    position: relative;
    flex: 0 0 197px;
  
    &-title {
      color: $white;
      text-align: center;
      position: absolute;
      z-index: 1;
    }
    &-cover {
      max-width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      position: relative;
      z-index: 2;
    }
    &-rowActions {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 10px);
      background: $black;
      background: linear-gradient(180deg, rgba($black, 0.5) 20%, rgba($black, 0) 100%);
      padding: 5px 5px 0;
      position: absolute;
      top: 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      z-index: 99;
    }
  }
  
  @media screen and (max-width: 720px) {
  
    .content {
      width: auto;
      height: 260px;
    }
  }