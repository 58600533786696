@import '../../../resources/styles/colors.scss';

.resetPasswordContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: $black;
    position: relative;

    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100vw;
        max-width: 400px;
        height: 65vh;
        gap: 20px;

        & input {
            width: calc(100% - 24px);
            padding: 12px;
        }
    }
}

@media screen and (max-width: 720px) {

    .resetPasswordContainer {
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: scroll;

        & .header {
            position: relative;
        }
        &-content {
            flex-direction: column;
            width: calc(100% - 30px);
            height: auto;
            padding: 0 15px;
            margin: 0;
            
            &_formColumn {
                width: 100%;
                border-right: none;
                overflow: inherit;
            }
        }
    }
}
