@import '../../resources/styles/colors.scss';

.chip {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: rgba($white,0.6);
    text-transform: capitalize;
    border: 1px solid rgba($white,0.15);
    border-radius: 20px;
    border-color: transparent;
    background-color: rgba($white,0.15);
    padding: 4px 8px;

    &.-selected {

        &.-movie {
            color: $movie;
            border-color: $movie;
        }
        &.-show {
            color: $tv-show;
            border-color: $tv-show;
        }
    }
}