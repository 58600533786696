@import '../../resources/styles/colors.scss';

.fixedBackground {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    &.-dark {
        background: $black;
        background: linear-gradient(180deg, rgba($black, 1) 0%, rgba($grey, 0.5) 70%);
    }
    &.-movie {
        background: $black;
        background: linear-gradient(180deg, rgba($black, 1) 0%, rgba($movie, 0.15) 70%);
    }
    &.-tvShow {
        background: $black;
        background: linear-gradient(180deg, rgba($black, 1) 0%, rgba($tv-show, 0.15) 70%);
    }
}