@import '../../../resources/styles/colors.scss';

@media screen and (max-width: 720px) {

    .header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: calc(100vw - 40px);
        padding: 15px 20px;
        gap: 20px;
        z-index: 10;

        &-logo {
            width: 10vh;
            max-width: 84px;
            height: 10vh;
            max-height: 84px;
            transform: none;
            cursor: pointer;
            position: relative;
            top: auto;
            left: auto;
        }
        &-actionColumn {
            display: flex;
            gap: 25px;
            flex: 1;
            justify-content: space-between;

            &_detailActions {
                display: flex;
                gap: 25px;
                align-items: center;
                justify-content: flex-start;
                padding-left: 10px;

                &-actionButton {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 4px;

                    & span {
                        font-size: 12px;
                        color: $white;
                        opacity: 0.6;
                    }
                }
            }
        }
        &-title {
            width: 100%;
            z-index: 10;

            & h1 {
                font-size: 28px;
                color: $white;
                text-align: center;
                margin: 10px 0;
                z-index: 10;
            }
        }
    }
}
