.gridCoversBackground {
    display: none;
}

@media screen and (max-width: 720px) {

    .gridCoversBackground {
        display: block;
        width: 150%;
        height: 150%;
        background-repeat: repeat-x; /* Ripete l'immagine solo sull'asse orizzontale */
        opacity: 0.2;
        animation: slide-background 20s linear infinite alternate;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;

        &_grid {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-auto-rows: 150px;
            column-gap: 8px;
            row-gap: 4px;
            transform: skew(-5deg);
            animation: diagonal-scroll 20s infinite linear alternate; 

            &Item {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                transform: skew(-5deg);
                overflow: hidden;

                & img {
                    max-width: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }
            }
        }
    }
}

@keyframes diagonal-scroll {

    0% {
      transform: translate(-25%, 0%);
    }
    100% {
      transform: translate(0%, 0%);
    }
  }

  @keyframes slide-background {

    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }