@import '../../resources/styles/colors.scss';

.installBanner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:$white;
    border-radius: 8px;
    background: $grey;
    padding: 10px;
    position: fixed;
    top: 20px;
    left: 10px;
    right: 10px;
    z-index: 9999;
    gap: 8px;

    &-description {
        display: flex;
        flex-direction: column;
        gap: 5px;

        & p {
            font-size: 16px;
            margin: 0;

            & > i {
                display: inline !important;
            }
        }
        &_actionsRow {
            display: flex;
            gap: 20px;
            flex-direction: row-reverse;
        }
    }
  }
  