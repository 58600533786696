@import '../../resources/styles/colors.scss';

.greyButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    border: 2px solid transparent;
    border-radius: 6px;
    background-color: $grey;
    padding: 5px;
    cursor: pointer;
    gap: 4px;

    &.-selected {

        &.-movie {
            color: $movie;
            border-color: $movie;
        }
        &.-show {
            color: $tv-show;
            border-color: $tv-show;
        }
    }
    & img {
        width: 35px;
        border-radius: 5px;
    }
    & span {
        max-width: calc(100% - 10px);
        font-size: 12px;
        color: $white;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-clamp: 1;
    }
}

