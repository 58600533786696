@import '../../resources/styles/colors.scss';

.multiFilterContainer {
  width: 100%;

  & h3 {
    font-size: 20px;
    font-weight: bold;
    color: $white;
  }
  &-container {
    height: 45vh;
    border-radius: 1vw;
    background-color: rgba($white,0.15);
    padding: 15px;

    &--multiSelection {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      max-height: 100%;
      overflow-y: scroll;
      gap: 8px;
    }
    &--gridSelection {
      display: grid;
      max-height: 100%;
      overflow-y: scroll;
      grid-template-columns: repeat(6, 1fr);
      gap: 8px;

      &_provider {
        width: 3.5vw;
        height: 3.5vw;
        border: 2px solid transparent;
        border-radius: 0.6vw;
        cursor: pointer;
      }
    }
  }
}
.selected {

  &.tv {
    border-color: $tv-show;
  }
  &.movie {
    border-color: $movie;
  }
}

@media screen and (max-width: 720px) {

  .multiFilterContainer {
    z-index: 2;

    &-container {

      &--gridSelection {
        flex-wrap: wrap;
        grid-template-columns: repeat(4, 1fr);

        & div {
          flex: 1;
        }
      }
    }
  }
}