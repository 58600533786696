@import '../../../resources/styles/colors.scss';

.header {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    &.-account {
        flex-direction: column;
        align-items: center;
    }
    &-tabSelector {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90vw;
        max-width: 1120px;
        border-bottom: 1px solid $grey-light;
        padding: 0 20px 20px;
        gap: 25px;
    }
    &-logo {
        width: 7vw;
        height: 7vw;
        transform: translate(-50%, 0);
        cursor: pointer;
        position: absolute;
        top: 20px;
        left: 50%;
    }
    &-suggest {
        flex: 1;

        &_detailActions {
            display: flex;
            justify-content: flex-end;
            color: $white;
            text-transform: uppercase;
            gap: 20px;

            & p {
                cursor: pointer;
            }
        }
    }
}
